import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import Recoil from 'recoil'
import './src/styles.css'


export const wrapRootElement:GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <Recoil.RecoilRoot>
      {element}
    </Recoil.RecoilRoot>
  )
}